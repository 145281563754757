require("@rails/activestorage").start()
require("@rails/ujs").start()

import '@fullcalendar/core'
import '@fullcalendar/daygrid'
import '@fullcalendar/timegrid'
import '@fullcalendar/list'
import '@fullcalendar/moment-timezone'

import 'moment'
import 'moment-timezone'

console.log('%cWelcome to Thrive', 'background: #83cdd9; color: white; padding: 25px 50px; border-radius: 5px; font-size: 2em;')

// User Time Zone
import jstz from 'jstz'
const timezone = jstz.determine();

function setCookie(name, value) {
  var expires = new Date()
  expires.setTime(expires.getTime() + (24 * 60 * 60 * 1000))
  document.cookie = name + '=' + value + ';expires=' + expires.toUTCString()
}

setCookie("timezone", timezone.name());
